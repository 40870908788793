@use './mixins' as m;
@use '@angular/material' as mat;
@use './np-theme.scss' as np;
@use './vars.scss' as vars;

@include mat.elevation-classes();
@include mat.app-background();

@mixin theme($theme) {
  @media (hover: hover) {
    ::-webkit-scrollbar-thumb {
      background-color: mat.get-theme-color($theme, inverse-surface);
      border-radius: vars.$search-border-radius;
    }

    ::-webkit-scrollbar {
      width: 6px;
    }
  }

  .katex-error {
    color: mat.get-theme-color($theme, error);
  }

  .npdc-overlay {
    background: mat.get-theme-color($theme, surface-container-highest);
  }

  app-search {
    background: mat.get-theme-color($theme, surface-container-highest);

    &:focus-within {
      box-shadow: inset 0 0 0 2px mat.get-theme-color($theme, primary);
    }
  }

  .npdc-leaflet {
    &__path {
      stroke: mat.get-theme-color($theme, primary);
      fill: mat.get-theme-color($theme, primary);
      fill-opacity: 0.2;
    }

    .leaflet-control-attribution,
    .leaflet-bar,
    .leaflet-bar a {
      background: mat.get-theme-color($theme, surface-container-lowest);
      color: mat.get-theme-color($theme, on-surface);
      border-color: var(--mdc-outlined-text-field-outline-color);

      &.leaflet-disabled {
        color: mat.get-theme-color($theme, inverse-on-surface);
      }
    }
  }

  .npdc-content__outlet {
    background: mat.get-theme-color($theme, surface);
  }

  mat-toolbar {
    background: mat.get-theme-color($theme, surface-container);
  }

  .mat-drawer-container,
  .mat-drawer {
    @include m.md {
      background: mat.get-theme-color($theme, surface-container);
    }
  }

  pre {
    background: mat.get-theme-color($theme, secondary-container);
  }

  .npdc-prefix-header {
    margin: vars.$default-margin 0 0 0;
    padding: 0 1.5em 0 0;
    border-style: solid;
    border-radius: vars.$container-border-radius;
    border-width: 0px;
    background: mat.get-theme-color($theme, surface-container);

    &__back {
      margin: 1.25em 0;
      height: 100%;
      border-radius: vars.$container-border-radius 0 0
        vars.$container-border-radius;
      border-style: solid;
      border-color: var(--mdc-outlined-text-field-outline-color);
      border-width: 0 1px 0 0;

      .mat-icon {
        font-size: 1.5em;
        padding: 0 0 0 0.5em;
      }
    }
  }

  a:not(
      [class^='mdc'],
      [class^='mat'],
      [class^='leaflet'],
      [class^='npdc-leaflet']
    ) {
    color: mat.get-theme-color($theme, primary);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .npdc-error-icon {
    @include mat.icon-color($theme, $color-variant: error);
  }

  .npdc-breadcrumb {
    @include mat.button-overrides(
      (
        text-label-text-color: mat.get-theme-color($theme, outline),
      )
    );

    &__active {
      @include mat.button-overrides(
        (
          text-disabled-label-text-color: mat.get-theme-color(
              $theme,
              on-surface
            ),
        )
      );
    }
  }
}

:root {
  @include mat.all-component-themes(np.$light-theme);

  @include theme(np.$light-theme);
  @media (prefers-color-scheme: dark) {
    @include mat.all-component-colors(np.$dark-theme);
    @include theme(np.$dark-theme);
  }
}

html,
body {
  height: 100%;
  width: 100%;

  @media (prefers-color-scheme: dark) {
    color-scheme: dark;
  }
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

code,
pre {
  font-family: 'Roboto Mono', monospace;
  border-radius: vars.$container-border-radius;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  padding: 1em;
}

// material overrides

// prevents long dialog titles from wrapping
.mat-mdc-dialog-title {
  @extend .npdc-title;
}

// allows wrapping of tabs (default is vertical scroll)
.mat-mdc-tab-labels {
  display: flex !important;
  flex-wrap: wrap;
}

mat-form-field {
  margin: 0.5rem 0;
}

// npdc

.npdc-stat-icon {
  margin: 0 1.75em 0 0;
}

.npdc-markdown {
  table {
    td {
      white-space: pre-wrap;
      word-wrap: break-word;
      word-break: break-all;
      white-space: normal;
    }
  }

  overflow-x: hidden;

  img {
    @extend .npdc-outline;

    max-width: 100%;
    height: auto;
  }
}

.npdc-input-spinner {
  margin: 0 12px;
}

.npdc-toolbar-button {
  width: 100%;
}

.npdc-tab {
  margin-top: vars.$default-margin;

  &__icon {
    margin-right: vars.$default-icon-margin;
  }
}

.npdc-file-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 4em;
  margin: 1em 0;
  width: 100%;

  &__mobile__search {
    display: flex;
    flex-direction: row;
    padding: 0.5em;
    align-content: center;
  }

  &__mode {
    display: flex;
    gap: 0.5em;
    justify-content: space-between;
    height: 100%;
    margin: 0.5em 0;
    align-content: center;
  }

  .file-search-form {
    width: 100%;

    @include m.md {
      display: flex;
      align-items: center;
    }

    &__input {
      font-size: 1em;
      padding: 0.5em;

      @include m.md {
        width: 100%;
        max-width: 800px;
      }
    }
  }
}

.npdc-empty-list {
  padding: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.npdc-content {
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__outlet {
    overflow: hidden;

    @include m.md {
      margin: 0 1rem 1rem 1rem;
      border-radius: vars.$container-border-radius;
    }
  }
}

.npdc-layout {
  height: 100%;
  overflow-y: auto;

  &__toolbar {
    padding: 1rem 1rem 0 1rem;
  }

  &__content {
    padding: 0 1rem 1rem 1rem;

    @include m.md {
      padding: 0 2rem 1rem 2rem;
    }
  }
}

.npdc-outline {
  border: 1px solid;
  border-color: var(--mdc-outlined-text-field-outline-color);
  border-radius: vars.$container-border-radius;
  overflow: hidden;
}

.npdc-table {
  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__left,
    &__right {
      display: flex;
      flex-direction: row;
      gap: vars.$default-button-margin;
      align-items: center;
    }
  }

  .mat-mdc-paginator {
    background-color: transparent !important;
  }
}

.npdc-basic-table {
  th {
    text-align: left;
  }

  th,
  td {
    padding: 0.5em;
  }
}

.npdc-title {
  overflow: hidden;
  text-overflow: ellipsis;
}

.npdc-leaflet {
  overflow: hidden;

  &__cursor-position {
    padding: 0 0.5em;
  }
}

.npdc-icon {
  &__file_json {
    background-image: url('/assets/symbol_data_object_dark.svg');

    @media (prefers-color-scheme: dark) {
      background-image: url('/assets/symbol_data_object_light.svg');
    }
  }
}

.npdc-search-container {
  width: 100%;

  @include m.md {
    justify-content: center;
    display: flex;
  }

  &__input {
    @include m.md {
      width: 100%;
      max-width: 800px;
    }
  }
}
