// should reflect breakpoint.model.ts

@mixin md {
  @media (min-width: 960px) {
    @content;
  }
}

@mixin lg {
  @media (min-width: 1920px) {
    @content;
  }
}
